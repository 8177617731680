.objEm {
  background: #fff;
  padding: 3vw;
  border-radius: 6vw 6vw 10vw 0;

  width: 94%;
  margin: 0 auto;
  margin-top: 8vw;
}
.objEm h4 {
  text-align: center;
  font-size: 7vw;
  margin-bottom: 3vw;
}
.objects {
  background: #d7f5ff;
  padding-bottom: 12vw;
  min-height: 100vh;
}
.hots {
  position: absolute;
  z-index: 22;
  top: 17vw;
  left: 6vw;
  width: 85%;
}
.hotEm {
  display: inline-block;
  width: fit-content;
  padding: 1vw 2vw;
  margin-bottom: 2vw;
  border-radius: 5vw;
  margin-right: 2vw;
}
.getPr {
  background: #7ec800;
  filter: drop-shadow(
    0px 4.7484660148620605px 26.11656379699707px rgba(101, 197, 67, 0.72)
  );
  padding: 4vw 10vw;
  padding-bottom: 2vw;
  width: 100%;
  text-align: center;
  font-size: 4vw;
  color: #fff;
  border-radius: 6vw 6vw 10vw 0;
  margin-top: 5vw;
  text-transform: uppercase;
}

@keyframes movingFlare {
  0% {
    left: -20vw;
    margin-left: 0px;
  }
  30% {
    left: 110%;
    margin-left: 80px;
  }
  100% {
    left: 110%;
    margin-left: 80px;
  }
}
.rel {
  position: relative;
}
.rel img {
  width: 100%;
}
.priceObj {
  position: absolute;
  bottom: 4vw;
  font-size: 5vw;
  right: 3vw;
  color: #43689d;
  padding: 1vw 2vw;
  background: #fff;
  border-radius: 6vw;
}
