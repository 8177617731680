.teamEm {
  display: flex;
  background: #fff;
  padding: 2vw;
  padding-right: 4vw;
  width: 94%;
  margin: 0 auto;
  border-radius: 0 8vw 8vw 8vw;
  filter: drop-shadow(0px 6px 25px rgba(56, 151, 239, 0.3));
  margin-top: 5vw;
}
.Team {
  background: #d7f5ff;
  padding-bottom: 5vw;
}
.teamEm img {
  width: 35vw;
  margin-right: 4vw;
}
.teamEm h4 {
  margin: 1vw 0;
  font-size: 4.5vw;
  margin-top: 2vw;
}
.line {
  width: 100%;
  height: 1px;
  background: rgba(0, 104, 255, 0.28);
}
.teamEm p {
  color: rgba(67, 104, 157, 1);
  margin: 1vw 0;
  font-size: 3vw;
}
.call {
  background: #7ec800;
  color: #fff;
  padding: 2.5vw 4vw;
  width: fit-content;
  border-radius: 10vw 0 10vw 6vw;
  filter: drop-shadow(0px 2px 11px rgba(101, 197, 67, 0.72));
  margin-top: 4vw;
}

@keyframes movingFlare {
  0% {
    left: -20vw;
    margin-left: 0px;
  }
  30% {
    left: 110%;
    margin-left: 80px;
  }
  100% {
    left: 110%;
    margin-left: 80px;
  }
}
